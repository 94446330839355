// core version + navigation, pagination modules:
import Swiper, {Navigation, Autoplay, EffectFade, Controller} from 'swiper';
import './components/contact'

// configure Swiper to use modules
Swiper.use([Navigation, Autoplay, EffectFade, Controller]);

//Swiper Home
new Swiper('#swiper-intro', {
    loop: true,
    autoplay: {
        delay: 5000,
    },
    // Navigation arrows
    navigation: {
        nextEl: '#swiper-intro .swiper-button-next',
        prevEl: '#swiper-intro .swiper-button-prev',
    },

});
const swiperAct = new Swiper('#swiper-activite-visuel', {
    effect: "fade",
    speed: 2000,
    loop: true,
    autoplay: {
        delay: 3000,
    },
    // Navigation arrows
    navigation: {
        nextEl: '.swipers .swiper-button-next',
        prevEl: '.swipers .swiper-button-prev',
    }
});
let swiperActText = new Swiper('#swiper-activite-text', {
    loop: true,
    speed: 1600,
    autoplay: {
        delay: 3000,
    },
    controller: {
        control: swiperAct
    },
    // Navigation arrows
    navigation: {
        nextEl: '.swipers .swiper-button-next',
        prevEl: '.swipers .swiper-button-prev',
    }
});
swiperAct.controller.control = swiperActText;


// Accordion List products
document.querySelectorAll(".list-product .entete").forEach((item) => {
    item.addEventListener("click", function () {
        this.classList.toggle('open-rotate');
        this.nextSibling.nextElementSibling.classList.toggle('open');
    });
});

// Ouverture Popup
document.querySelectorAll(".list-product article ul li a").forEach((item) => {
    item.addEventListener("click", function (e) {
        e.preventDefault();

        if (document.querySelector("#popup-product .icons .active")) {
            document.querySelector("#popup-product .icons .active").classList.remove("active");
        }
        document.querySelector("#popup-product .icons ." + item.dataset.category).classList.add("active");

        if (document.querySelector("#popup-product article.active")) {
            document.querySelector("#popup-product article.active").classList.remove("active");
        }
        document.querySelector("#popup-product #" + item.getAttribute("href").replace("#", "")).classList.add("active");
        setTimeout(() => {
            document.body.classList.add("popup-active");
        }, 150)

    });
});

// Fermeture popup
document.querySelector("#popup-product .actions button").addEventListener("click", function (e) {
    e.preventDefault();
    document.body.classList.remove("popup-active");
});

document.querySelectorAll(".list-products ul li a").forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        let elem = document.querySelector(item.getAttribute("href"));
        window.scrollTo({
            top: document.querySelector(item.getAttribute("href")).offsetTop - 80,
            behavior: 'smooth',
        });
        elem.querySelector("ul").classList.add('open');


    })
})


document.querySelectorAll("header nav li a").forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        document.body.classList.toggle('menu-open');
        window.scrollTo({
            top: document.querySelector(item.dataset.goto).offsetTop - 50,
            behavior: 'smooth',
        })
    })
})




