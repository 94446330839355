import axios from "axios";

let sendingMsg = document.querySelector('.sending-contact');
let errorMsg = document.querySelector('.error-contact');
let successMsg = document.querySelector('.success-contact');
let form = document.querySelector('#form-contact-pi');


window.onload = function () {
    let recaptcha = document.querySelector('#g-recaptcha-response');
    if (recaptcha) {
        recaptcha.setAttribute("required", "required");
    }
    recaptcha.oninvalid = function (e) {
        alert("Etes vous un robot ?");
    }
};

form.addEventListener('submit', (e) => {
    e.preventDefault();
    form.style.display = 'none';
    sendingMsg.style.display = 'block';
    let form2 = document.querySelector('#form-contact-pi');

    let bodyFormData = new FormData(form2);

    /*bodyFormData.append('firstname', document.querySelector('#firstname'));
    bodyFormData.append('lastname', document.querySelector('#lastname'));
    bodyFormData.append('phone', document.querySelector('#phone'));
    bodyFormData.append('email', document.querySelector('#email'));
    bodyFormData.append('g-recaptcha-response', document.querySelector('#g-recaptcha-response'));*/

    axios({
        method: "post",
        url: "send-mail.php",
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
    })
        .then(function (response) {
            //handle success
//            console.log(response);
            sendingMsg.style.display = 'none';
            successMsg.style.display = 'block';
            form.remove();
        })
        .catch(function (response) {
            //handle error
            sendingMsg.style.display = 'none';
            errorMsg.style.display = 'block';
            form.remove();
            console.log(response);
        });


    return false;
});